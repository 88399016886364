import logo from './logo.svg';
import './App.css';
import React, {useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { FilloutStandardEmbed, FilloutFullScreenEmbed } from "@fillout/react";
import "@fillout/react/style.css";

const userData = {
    formObj: {
        consentsSigned: Boolean,
        firstName: String,
        lastName: String,
        dob: String,
        phone: String,
        email: String,
        address: String,
        city: String,
        state: String,
        zip: String,
        sex: String,
        selfReportedMeds: String,
        allergies: String,
        medicalConditions: String,
        patientPreference: Array [
            {
                name: String,
                strength: String,
                quantity: String,
                refills: String,
                medId: String,
            }
            ],
        Q1: String,
        A1: String,
    },
    pharmacyId: String,
    masterId: String,
    company: String,
    visitType: String
}

function GettingStartedForm() {
    function handleSubmit(event) {
        event.preventDefault();
        let formIsValid = true;
        let errors = [];
        userData.formObj.firstName = event.target.firstName.value;
        userData.formObj.lastName = event.target.lastName.value;
        userData.formObj.phone = event.target.phone.value;
        userData.formObj.email = event.target.email.value;
        userData.formObj.address = event.target.address.value;
        userData.formObj.city = event.target.city.value;
        userData.formObj.state = event.target.state.value;
        userData.formObj.zip = event.target.zip.value;
        userData.formObj.dob = event.target.dob.value;
        if (!userData.formObj.firstName) {
            formIsValid = false;
            errors.push('First name is required');
        }
        if (!userData.formObj.lastName) {
            formIsValid = false;
            errors.push('Last name is required');
        }
        if (!userData.formObj.phone) {
            formIsValid = false;
            errors.push('Phone number is required');
        }
        if (!userData.formObj.email) {
            formIsValid = false;
            errors.push('Email is required');
        }
        if (!userData.formObj.address) {
            formIsValid = false;
            errors.push('Address is required');
        }
        if (!userData.formObj.city) {
            formIsValid = false;
            errors.push('City is required');
        }
        if (!userData.formObj.state) {
            formIsValid = false;
            errors.push('State is required');
        }
        if (!userData.formObj.zip) {
            formIsValid = false;
            errors.push('Zip code is required');
        }
        if (!userData.formObj.dob) {
            formIsValid = false;
            errors.push('Date of Birth is required');
        }

        console.log(userData.formObj);
    }

    return (
        <div style={{height:500}}>
        <div  data-fillout-id="twoJmqgQ4qus" data-fillout-embed-type="standard" data-fillout-inherit-parameters data-fillout-dynamic-resize data-fillout-domain="hello.coremedconnect.com"></div><script src="https://server.fillout.com/embed/v1/"></script>
        </div>
        // <form onSubmit={handleSubmit}>
        //     <h1>Getting Started</h1>
        //     <BasicTextField
        //         nameId="firstName"
        //         publicName="First Name"
        //     />
        //     <BasicTextField
        //         nameId="lastName"
        //         publicName="Last Name"
        //     />
        //     <EmailField/>
        //     <PhoneField/>
        //     <BasicTextField
        //         nameId="address"
        //         publicName="Address"
        //     />
        //     <BasicTextField
        //         nameId="city"
        //         publicName="City"
        //     />
        //     <SelectState/>
        //     <ZipField/>
        //     <label>Date of Birth:
        //     </label>
        //     <PickDate/>
        //     <BasicTextField
        //         nameId="cardNumber"
        //         publicName="Credit Card Number"
        //     />
        //     <BasicTextField
        //         nameId="expDate"
        //         publicName="4 - digit Expiration Date"
        //     />
        //     <BasicTextField
        //         nameId="cvv"
        //         publicName="CVV"
        //     />
        //     <BasicTextField
        //         nameId="checkingAccountNumber"
        //         publicName="Checking Account Number"
        //     />
        //     <BasicTextField
        //         nameId="bankRoutingNumber"
        //         publicName="Bank Routing Number"
        //     />
        //     <button type="submit">Submit</button>
        // </form>
    )
}

function BasicTextField({nameId, publicName}) {
    return (
        <div>
            <label htmlFor={nameId}>{publicName}:</label>
            <input name={nameId} type="text" placeholder={publicName}/>
        </div>

    )
}

function EmailField() {
    return (
        <BasicTextField
            nameId="email"
            publicName="Email Address"
        />
    )
}

function PhoneField() {
    return (
        <BasicTextField
            nameId="phone"
            publicName="Phone Number"
        />
    )
}

function ZipField() {
    return (
        <BasicTextField
            nameId="zip"
            publicName="Zip Code"
        />
    )
}

function PickDate() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <DatePicker name="dob" selected={startDate} onChange={(date) => setStartDate(date)}/>
    )
}

function SelectState() {
    return (
        <label htmlFor="state">
            State:
            <select id="state">
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>

            </select>
        </label>
    )
}

function YesAndNoButtons({id}) {
    return (
        <div className="button-group" id={id}>
            <button type="button" data-value="yes">Yes</button>
            <button type="button" data-value="no">No</button>
        </div>
    )
}

function RequiredLabel({label}) {
    return (
        <label className="required">{label}</label>
    )
}

function TextFieldRequired({divId, textId, label}) {
    return (
        <div id={divId}>
            <label htmlFor={textId} className="required">{label}</label>
            <textarea id={textId} name={textId} required></textarea>
        </div>
    )
}



function App() {
    return (
        <div>
            <FilloutFullScreenEmbed filloutId="twoJmqgQ4qus" inheritParameters />
        </div>
    );
}

export default App;
